/* Webfont: munki-boylogo */@font-face {
    font-family: 'munki-boy';
    src: url('../../dist/fonts/munki-boylogo.eot'); /* IE9 Compat Modes */
    src: url('../../dist/fonts/munki-boylogo.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../dist/fonts/munki-boylogo.woff') format('woff'), /* Modern Browsers */
         url('../../dist/fonts/munki-boylogo.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../dist/fonts/munki-boylogo.svg#munki-boylogo') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}